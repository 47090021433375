<template>
    <div>
        <a-modal :title="getTitle" :width="620" :visible="visible" @cancel="handleCancel">
            <template slot="footer">
                <a-button key="back" @click="handleCancel">
                    取消
                </a-button>
                <a-button key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
                    保存
                </a-button>
            </template>
            <a-spin :spinning="loading">
                <div>
                    <div>
                        <div class="flex center">
                            <upload-img v-model="form.face"></upload-img>
                        </div>
                        <div class="flex center mt8">
                            <div class="ft12 ftw400 cl-notice">头像：建议上传200x200px</div>
                        </div>
                    </div>
                    <div class="mt20 bg-w pd40">

                        <a-form-model :model="form" ref="ruleForm" :label-col="labelCol" :wrapper-col="wrapperCol"
                                      :rules="rules">
                            <a-form-model-item ref="username" required label="名称" help="20字以内"
                                               :hasFeedback="true" prop="username">
                                <a-input placeholder="请输入名称" v-model="form.username" :maxLength="20"></a-input>
                            </a-form-model-item>
                            <a-form-model-item ref="mobile" required label="手机号" help="此手机号用于账号登录"
                                               :hasFeedback="true" prop="mobile">
                                <a-input placeholder="请输入手机号" v-model="form.mobile"></a-input>
                            </a-form-model-item>
                            <a-form-model-item ref="password" :required="pwdRequired" label="密码" has-feedback
                                               :hasFeedback="true" prop="password">
                                <a-input-password placeholder="请输入账号密码" v-model="form.password"/>
                            </a-form-model-item>
                            <a-form-model-item required label="权限组">
                                <a-select v-model="form.group_id" >
                                    <a-select-option :value="0" :key="-1" >
                                        选择权限组
                                    </a-select-option>
                                    <a-select-option  v-for="group in groupData" :key="group.group_id" >
                                        {{ group.title }}
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                            <a-form-model-item required label="性别">
                                <a-radio-group v-model="form.sex">
                                    <a-radio :value="2">男</a-radio>
                                    <a-radio :value="1">女</a-radio>
                                </a-radio-group>
                            </a-form-model-item>


                            <a-form-model-item label="出生年月">
                                <a-date-picker
                                        format="YYYY-MM-DD"
                                        @change="changeTime"
                                        :default-value="moment(form.birthday, dateFormat)"
                                        :value="form.birthday"
                                />
                            </a-form-model-item>
                            <a-form-model-item label="状态">
                                <a-switch checked-children="正常" un-checked-children="冻结" v-model="form.status"/>
                            </a-form-model-item>
                            <a-form-model-item label="备注">
                                <a-input v-model="form.remarks" type="textarea"/>
                            </a-form-model-item>


                        </a-form-model>
                    </div>
                </div>
            </a-spin>
        </a-modal>
    </div>
</template>

<script>
import uploadImg from "@/components/upload/upload.vue";
import moment from 'moment';

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        account_id: {
            type: Number,
            default: 0,
        }
    },
    components: {
        uploadImg,
    },
    data() {
        return {
            dateFormat: 'YYYY-MM-DD',
            loading: false,
            pwdRequired: true,
            confirmLoading: false,
            labelCol: {span: 4},
            wrapperCol: {span: 20},
            rules: {
                username: [
                    {required: true, message: '请填写名称', trigger: 'blur'},
                ],
                mobile: [
                    {required: true, message: '手机号不能为空', trigger: 'blur'},
                    {pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, message: '请输入正确的手机号', trigger: 'blur'},
                ],
                password: [
                    {validator: this.checkPassword, trigger: 'change'},
                ],
            },
            groupData:[],
            form: {
                account_id: 0,
                group_id: 0,
                face: '',
                name: '',
                mobile: '',
                password: '',
                remarks: '',
                sex: 2,
                birthday: '1990-01-01',
                status: true
            },
        }
    },
    computed: {
        getTitle() {
            if (this.account_id === 0) {
                return "添加运营";
            } else {
                return "编辑运营"
            }
        }
    },

    created() {
        if (this.account_id) {
            this.pwdRequired = false;
        }
        this.loaddata();
    },
    methods: {
        moment,
        loaddata() {
            if (this.loading === true) return;
            this.loading = true;
            this.$http.api('admin/getAccountInfo', {
                account_id: this.account_id,
            }).then(res => {
                if(res.detail instanceof Object) {
                    if (Object.keys(res.detail).length !== 0) {
                        this.form = res.detail;
                    }
                }
                this.groupData = res.groupData;
                this.loading = false;
            }).catch(res => {
                console.log(res);
                this.loading = false;
            })
        },
        changeTime(date, dateString) {
            this.form.birthday = dateString;
        },
        /**
         * 取消弹窗
         */
        handleCancel() {
            this.$emit("cancel");
        },
        //校验密码
        checkPassword(rule, value, callback) {
            if (this.account_id) {
                return callback()
            }
            if(!value){
                return callback('密码不能为空');
            }
            if (value.length < 8) {
                return callback('密码不少于8位')
            }
            if (value.length > 20) {
                return callback('密码不大于20位')
            }
            // 校验是数字
            const regex1 = /^\d+$/

            if (regex1.test(value)) {
                return callback('密码不允许为纯数字')
            }
            // eslint-disable-next-line no-unused-vars
            var modes = 0;
            if(/\d/.test(value)){       //如果用户输入的密码 包含了数字
                modes++;
            }
            if(/[a-z]/.test(value)){    //如果用户输入的密码 包含了小写的a到z
                modes++;
            }
            if(/[A-Z]/.test(value)){    //如果用户输入的密码 包含了大写的A到Z
                modes++;
            }
            if(/\W/.test(value)){       //如果是非数字 字母 下划线
                modes++;
            }
            if(modes < 3){
                return callback('密码同时存在大写字母、小写字母、符号、数字任意3种组合')
            }
            return callback()
        },
        /**
         * 提交数据
         */
        onSubmit() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    if (this.form.password === '') {
                        this.confirmLoading = false;
                        this.$message.error('请填写密码');
                        return;
                    }
                    if(this.form.group_id === 0){
                        this.confirmLoading = false;
                        this.$message.error('请选择一个权限组');
                        return;
                    }


                    if (this.confirmLoading === true) return;
                    this.confirmLoading = true;
                    this.form.account_id = this.account_id;
                    this.$http.api('admin/saveAccountData', this.form).then(res => {
                        this.$message.success('保存成功', 1, () => {
                            this.confirmLoading = false;
                            this.$emit("ok");
                        })

                    }).catch(res => {
                        console.log(res);
                        this.confirmLoading = false;
                    })
                } else {
                    this.$message.error('必填项不能为空');
                    return false;
                }
            });
        },

    }
}
</script>

